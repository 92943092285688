import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext, UserContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { fetchAreas, fetchUpdateOrCreateUser } from '../../requests';
import { ButtonType, SnackbarType, UserRole } from '../../types/enums';
import Form from '../Form/Form';
import Popup from '../Popup/Popup';
import {
  USER_ROLE_LABELS,
  hasMinLength,
  isValidEmail,
  isValidPhoneNumber,
  replaceEmptyStringFieldValueWithNull,
  validateItem
} from '../../utils/common';
import Input from '../Input/Input';
import useData from '../../hooks/useData';
import { User, Area, Validation, FormRowBody } from '../../types/types';
import Checkbox from '../Checkbox/Checkbox';
import office from '../MezzaForm/inputs/Office';

interface UserFormProps {
  users: User[];
  onExit: (shouldReload?: boolean) => void;
  initialUser?: User;
}

type UserFormErrors = Record<keyof User, string>;

const UserForm = ({ users, onExit, initialUser = EMPTY_USER }: UserFormProps) => {
  const notify = useContext(NotificationContext);
  const [areas] = useData(fetchAreas);
  const [user, setUser] = useState(initialUser);
  const [formErrors, setFormErrors] = useState<UserFormErrors>({} as UserFormErrors);
  const [area, setArea] = useState<Area | undefined>(user.area);
  const [showApproverCheck, setShowApproverCheck] = useState(false);

  const AREA_OPTIONS = useMemo(
    () => [{ key: 'no-selection', value: placeholders.select }, ...constructAreaOptions(areas)],
    [areas]
  );

  useEffect(() => {

    if(user?.area?.office_id && String(process.env.REACT_APP_MEZZA_APPROVER_OFFICE_IDS)
      .split(',')
      .includes(String(user?.area?.office_id))) {
      setShowApproverCheck(true);
    } else {
      setShowApproverCheck(false);
    }
    return () => {
      setFormErrors({} as UserFormErrors);
    };
  }, []);

  const checkForEmailDuplication = useCallback(
    (email: string) => {
      const userByEmail = users.find((u) => u.email === email);
      return !(userByEmail && userByEmail.id !== user.id);
    },
    [users, user]
  );

  const onSuccess = useCallback(() => {
    notify(
      user.id === -1
        ? 'Az új felhasználó sikeresen létrehozva.'
        : 'A felhasználó frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [user, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      user.id === -1
        ? 'Az új felhasználó létehozása nem sikerült.'
        : 'A felhasználó frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [user, notify]);

  const onSave = useCallback(() => {
    if (user) {
      const completeUser: User = { ...user, area_id: area?.id || null };
      const errors = validateItem(
        completeUser,
        getValidations(completeUser, checkForEmailDuplication)
      );
      setFormErrors(errors as UserFormErrors);

      if (Object.keys(errors).length === 0 && !isEqual(completeUser, initialUser)) {
        fetchUpdateOrCreateUser(
          replaceEmptyStringFieldValueWithNull(completeUser),
          onSuccess,
          onError
        );
      }
    }
  }, [user, initialUser, onError, onSuccess, area, checkForEmailDuplication]);

  return (
    <Popup
      show
      title={user.id === -1 ? titles.createUser : titles.editUser}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        },
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        }
      ]}
    >
      <Form
        data={user}
        onChange={(val) => setUser(val as User)}
        rows={USER_FORM_ROWS}
        errors={formErrors}
      />
      <Input
        id="areaId"
        value={area?.code ?? placeholders.select}
        setValue={(val) => setArea(areas.find((it) => it.id === +val))}
        label={inputLabels.areaCode}
        options={AREA_OPTIONS}
        placeholder={placeholders.select}
        error={formErrors.area_id}
      />
      <Input
        id="officeName"
        value={area?.officeName ?? ''}
        label={inputLabels.officeName}
        readOnly
      />
      <Input
        id="accessRight"
        value={
          (
            ACCESS_RIGHTS.find(({ key }) => key === user.role) ?? {
              value: USER_ROLE_LABELS[UserRole.USER]
            }
          ).value
        }
        setValue={(val) => setUser((user) => ({ ...user, role: val as UserRole }))}
        label={inputLabels.accessRight}
        options={ACCESS_RIGHTS}
      />
      {(area?.office_id && String(process.env.REACT_APP_MEZZA_APPROVER_OFFICE_IDS)
        .split(',')
        .includes(String(area?.office_id))) ? (
      <Checkbox
        id="mezzaApprover"
        value={user.mezza_approver}
        setValue={(val) => setUser((user) => ({ ...user, mezza_approver: val }))}
        label={inputLabels.mezzaApprover}
      />
      ) : null}
      <Checkbox
        id="retrieveEmails"
        value={user.notifiable}
        setValue={(val) => setUser((user) => ({ ...user, notifiable: val }))}
        label={inputLabels.getsEmails}
      />
    </Popup>
  );
};

const constructAreaOptions = (areas: Area[]) =>
  areas.map(({ id, code }) => ({ key: String(id), value: code }));

const ACCESS_RIGHTS = [
  { key: UserRole.USER, value: USER_ROLE_LABELS[UserRole.USER] },
  {
    key: UserRole.AREA_MANAGER,
    value: USER_ROLE_LABELS[UserRole.AREA_MANAGER]
  },
  {
    key: UserRole.OFFICE_MANAGER,
    value: USER_ROLE_LABELS[UserRole.OFFICE_MANAGER]
  },
  { key: UserRole.ADMIN, value: USER_ROLE_LABELS[UserRole.ADMIN] }
];

const getValidations = (
  user: User,
  checkForEmailDuplication: (email: string) => boolean
): Validation<User>[] => [
  {
    field: 'name',
    validate: (val: string) => hasMinLength(val, 5),
    error: `${errors.mandatoryTextField}\r\nMinimum 5, maximum 190 karakter.`
  },
  {
    field: 'email',
    validate: (val: string) => !!isValidEmail(val) && checkForEmailDuplication(val),
    error: `${errors.emailFormat}\r\nEz az email cím foglalt lehet.`
  },
  {
    field: 'phone_number',
    validate: (val: string) => val === '' || val === null || !!isValidPhoneNumber(val),
    error: errors.phoneNumber
  },
  {
    field: 'area_id',
    validate: (val: string) =>
      [UserRole.AREA_MANAGER, UserRole.OFFICE_MANAGER].includes(user.role) ? !!val : true,
    error: errors.areaId
  }
];

const EMPTY_USER: User = {
  id: -1,
  name: '',
  email: '',
  phone_number: '',
  area_id: null,
  role: UserRole.USER,
  mezza_approver: false,
  notifiable: true
};

const USER_FORM_ROWS: {
  [key: string]: FormRowBody;
}[] = [
  {
    name: {
      header: inputLabels.name,
      placeholder: placeholders.name
    }
  },
  {
    email: { header: inputLabels.email, placeholder: placeholders.userEmail }
  },
  {
    phone_number: {
      header: inputLabels.phone,
      placeholder: placeholders.userPhone
    }
  }
];

export default UserForm;
