import { buttonLabels, titles } from '../../data/labels';
import { AppRoute } from '../../types/enums';
import Sidebar from './Sidebar';
import SidebarMenuItem from './SidebarMenuItem';

import { ReactComponent as Arrow } from './icons/submenu-arrow.svg';
import { hasMezzaAccess, hasMezzaApproveAccess, isGruppoOfficer } from '../../utils/auth';
import { useContext } from 'react';
import { UserContext } from '../../App';

const MezzaSidebar = () => {
  const { user, setUser } = useContext(UserContext);

  return (
    <Sidebar title={titles.mezzaSystem}>
      <>
      {user && hasMezzaAccess(user) ? (
        <>
          <SidebarMenuItem target={AppRoute.MEZZA_INCOMING} icon={Arrow}>
            {buttonLabels.mezzaIncoming}
          </SidebarMenuItem>
          <SidebarMenuItem target={AppRoute.MEZZA_OUTGOING} icon={Arrow}>
            {buttonLabels.mezzaOutgoing}
          </SidebarMenuItem>
          <SidebarMenuItem target={AppRoute.MEZZA_CLOSED} icon={Arrow}>
            {buttonLabels.mezzaClosed}
          </SidebarMenuItem>
        </>
        ) : null}
        {user && (hasMezzaApproveAccess(user) || isGruppoOfficer(user)) ? (
        <SidebarMenuItem target={AppRoute.MEZZA_APPROVE} icon={Arrow}>
          {buttonLabels.mezzaApprove}
        </SidebarMenuItem>
          ) : null}
        <SidebarMenuItem primary target={AppRoute.MEZZA_RULES} icon={Arrow}>
          {buttonLabels.rules}
        </SidebarMenuItem>
      </>
    </Sidebar>
  );
};

export default MezzaSidebar;
