import { API, MezzaGroup, MezzaState } from '../types/enums';
import {
  Office,
  Area,
  User,
  PropertyGroup,
  Mezza,
  MapData,
  DocumentCategory,
  Document,
  MezzaLog,
  MezzaNotification,
  HighLightedOfficeData,
  CalendarCategory,
  CalendarEvent, EditedArea
} from '../types/types';
import { isAdminPage } from '../utils/common';
import { axiosInstance } from './axios';
import { b } from '@fullcalendar/core/internal-common';
import { log } from 'node:util';

export const fetchOffices = () => axiosInstance.get<{ items: Office[] }>(API.OFFICES);

export const fetchRegularOffices = () =>
  axiosInstance.get<{ items: Office[] }>(API.REGULAR_OFFICES);

export const fetchHighlightedOffices = () =>
  axiosInstance.get<{ items: HighLightedOfficeData[] }>(API.HIGHLIGHTED_OFFICES);

export const fetchArea = (areaId: number) =>
  axiosInstance.get<{ area: Area }>(`${API.AREAS}/${areaId}`);

export const fetchAreas = () => axiosInstance.get<{ items: Area[] }>(API.AREAS);

export const fetchUsers = () => axiosInstance.get<{ items: User[] }>(API.USERS);

export const fetchUpdateArea = (
  areaId: number,
  code: string,
  officeId: number | undefined,
  status: string
) => axiosInstance.patch(`${API.AREAS}/${areaId}`, { code, officeId, status });

export const fetchPropertyGroups = ({ areaId }: { areaId: string }) =>
  axiosInstance.get<{ items: PropertyGroup[] }>(`${API.PROPERTY_GROUPS}/${areaId}`);

const fetchUpdateOrCreate = (
  endpoint: API,
  item: Record<string, any>,
  onSuccess: () => void,
  onError: () => void
) => {
  axiosInstance
    .post(endpoint, item)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

export const fetchUpdateOrCreatePropertyGroup = (
  property: PropertyGroup,
  onSuccess: () => void,
  onError: () => void
) => fetchUpdateOrCreate(API.PROPERTY_GROUPS, property, onSuccess, onError);

export const fetchUpdateOrCreateOffice = (
  office: Office,
  onSuccess: () => void,
  onError: () => void
) => fetchUpdateOrCreate(API.OFFICES, office, onSuccess, onError);

export const fetchDeletePropertyGroup = (propertyId: number) =>
  axiosInstance.delete(`${API.PROPERTY_GROUPS}/${propertyId}`);

export const fetchDeleteOffice = (officeId: number) =>
  axiosInstance.delete(`${API.OFFICES}/${officeId}`);

export const fetchDeleteUser = (userId: number) => axiosInstance.delete(`${API.USERS}/${userId}`);

export const fetchUpdateOrCreateUser = (user: User, onSuccess: () => void, onError: () => void) =>
  fetchUpdateOrCreate(API.USERS, user, onSuccess, onError);

export const fetchLoginByToken = (token: string, isMfaToken: boolean) =>
  axiosInstance.post<{ token: string; expires: string; user: User }>(API.LOGIN_WITH_TOKEN, {
    token,
    isMfaToken,
    isAdminLogin: isAdminPage()
  });

export const fetchLoginByCredentials = (email: string, password: string) =>
  axiosInstance.post<{ expires: string }>(API.LOGIN_WITH_CREDENTIALS, {
    email,
    password,
    isAdminLogin: isAdminPage()
  });

export const fetchFreshToken = () => axiosInstance.get(API.REFRESH_TOKEN);

export const fetchForgotPassword = (email: string) =>
  axiosInstance.post(API.FORGOT_PASSWORD, { email });

export const fetchResetPassword = (password: string, passwordResetToken: string) =>
  axiosInstance.post(API.RESET_PASSWORD, { password, passwordResetToken });

export const fetchChangePassword = (password: string, oldPassword: string) =>
  axiosInstance.post(API.CHANGE_PASSWORD, { password, oldPassword });

export const logout = () => axiosInstance.get(API.LOGOUT);

// avoid forbidden Authorization header by axios interceptor
export const getLocationByAddress = (address: string) =>
  fetch(`${API.GOOGLE_MAPS_GEOCODE}address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`)
    .then((res) => res.json())
    .then((res) => res.results[0]?.geometry?.location)
    .catch((e) => {
      console.error(e);
      return false;
    });

export const fetchDocumentCategories = () =>
  axiosInstance.get<{ items: DocumentCategory[] }>(API.DOCUMENT_CATEGORIES);

export const fetchDocumentCategory = (categoryId: number) =>
  axiosInstance.get<{ documentCategory: DocumentCategory }>(
    `${API.DOCUMENT_CATEGORIES}/${categoryId}`
  );

export const fetchDeleteDocumentCategory = (documentCategoryId: number) =>
  axiosInstance.delete(`${API.DOCUMENT_CATEGORIES}/${documentCategoryId}`);

export const fetchUpdateOrCreateDocumentCategory = (
  documentCategory: DocumentCategory,
  onSuccess: () => void,
  onError: () => void
) => fetchUpdateOrCreate(API.DOCUMENT_CATEGORIES, documentCategory, onSuccess, onError);

export const fetchDocuments = ({ categoryId }: { categoryId: string }) =>
  axiosInstance.get<{ items: Document[] }>(`${API.DOCUMENTS}/${categoryId}`);

export const fetchDeleteDocument = (documentId: number) =>
  axiosInstance.delete(`${API.DOCUMENTS}/${documentId}`);

export const fetchUpdateOrCreateDocument = (doc: any, onSuccess: () => void, onError: () => void) =>
  axiosInstance
    .post(API.DOCUMENTS, doc, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const fetchDownloadDocument = (doc: Document, onSuccess: () => void, onError: () => void) =>
  axiosInstance({
    url: `${API.DOWNLOAD}/${doc.id}`,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', doc.path);
      document.body.appendChild(link);
      link.click();
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const fetchOpenDocument = (doc: Document, onSuccess: () => void, onError: () => void) => {
  axiosInstance({
    url: `${API.DOWNLOAD}/${doc.id}`,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const extension = doc.path.split('.').pop();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: extension == 'pdf' ? 'application/pdf' : 'image/' + extension
        })
      );
      window.open(url);
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

export const checkMezzaOfficeAddress = (cityCheck: string,
                                        street: string,
                                        streetType: string,
                                        houseNumber: string) =>
  axiosInstance.post<{data: string}>(`${API.MEZZA_ADDRESS_CHECK}`, {
    cityCheck,
    street,
    streetType,
    houseNumber
  });

export const fetchMezzaList = (group: MezzaGroup) =>
  axiosInstance.get<{ items: Mezza[] }>(`${API.MEZZA}?group=${group}`);

export const fetchMezza = (id: string) => axiosInstance.get<Mezza>(`${API.MEZZA}/${id}`);

export const createMezza = (
  mezza: Mezza | Record<string, any>,
  onSuccess: () => void,
  onError: () => void,
  needGruppoCheck: number
) => {
  mezza.needGruppoCheck = needGruppoCheck;
  console.log(mezza);
  fetchUpdateOrCreate(API.MEZZA, mezza, onSuccess, onError);
}

export const updateMezza =
  (id: number) =>
  (mezza: Mezza | Record<string, any>, onSuccess: () => void, onError: () => void, needGruppoCheck: number) =>
    axiosInstance
      .patch(`${API.MEZZA}/${id}`, mezza)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });

export const fetchMapDataServerVersion = () =>
  axiosInstance.get<{ version: string }>(API.MAP_DATA_VERSION);

export const fetchMapData = () => axiosInstance.get<{ items: MapData }>(API.MAP_DATA);

export const fetchOfficeUsers = (officeId: string) =>
  axiosInstance.get<{ items: User[] }>(`${API.OFFICE_USERS}/${officeId}`);

export const updateMezzaAssignee = (
  mezzaId: string,
  userId: string,
  assigneeRole: 'to_user_id' | 'from_user_id'
) =>
  axiosInstance.patch<Mezza>(`${API.MEZZA}/${mezzaId}/assign`, {
    assigneeRole,
    userId
  });

export const updateMezzaState = (mezzaId: string | number, state: MezzaState, note: string) =>
  axiosInstance.patch<Mezza>(`${API.MEZZA}/${mezzaId}/state`, {
    state,
    note
  });

export const updateApprovedMezzaState = (mezzaId: string | number, state: MezzaState, note: string, office: string) =>
  axiosInstance.patch<Mezza>(`${API.MEZZA}/${mezzaId}/state`, {
    state,
    note,
    office
  });

export const sendMezzaMessage = (mezzaId: string | number, message: string) =>
  axiosInstance.post(`${API.MEZZA}/${mezzaId}/message`, {
    message
  });

export const fetchMezzaLogs = () => axiosInstance.get<{ items: MezzaLog[] }>(API.MEZZA_LOG);

export const fetchMezzaNotification = (id: number | string) =>
  axiosInstance.get<MezzaNotification>(`${API.MEZZA_NOTIFICATION}/${id}`);

export const updateMezzaNotification = (id: number | string, settings: MezzaNotification) =>
  axiosInstance.patch(`${API.MEZZA_NOTIFICATION}/${id}`, { settings });

export const updateUserNotifications = (wantsNotification: boolean) =>
  axiosInstance.patch(API.USER_NOTIFICATIONS, { wantsNotification });

export const fetchCalendarCategories = () =>
  axiosInstance.get<{ items: CalendarCategory[] }>(API.CALENDAR_CATEGORIES);

export const createCalendarCategory = (
  data: Omit<CalendarCategory, 'id'>,
  onSuccess: () => void,
  onError: () => void
) =>
  axiosInstance
    .post(API.CALENDAR_CATEGORIES, data)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const updateCalendarCategory = (
  data: CalendarCategory,
  onSuccess: () => void,
  onError: () => void
) =>
  axiosInstance
    .patch(`${API.CALENDAR_CATEGORIES}/${data.id}`, data)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const deleteCalendarCategory = (id: CalendarCategory['id']) =>
  axiosInstance.delete(`${API.CALENDAR_CATEGORIES}/${id}`);

export const fetchCalendarCategoryEvents = (id: CalendarCategory['id']) =>
  axiosInstance.get<{
    category: CalendarCategory;
    events: CalendarEvent[];
  }>(`${API.CALENDAR_CATEGORIES}/${id}`);

export const createCalendarEvent = (
  data: Omit<CalendarEvent, 'id'>,
  onSuccess: () => void,
  onError: () => void
) =>
  axiosInstance
    .post(API.CALENDAR_EVENTS, data)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const updateCalendarEvent = (
  data: CalendarEvent,
  onSuccess: () => void,
  onError: () => void
) =>
  axiosInstance
    .patch(`${API.CALENDAR_EVENTS}/${data.id}`, data)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

export const deleteCalendarEvent = (id: CalendarEvent['id']) =>
  axiosInstance.delete(`${API.CALENDAR_EVENTS}/${id}`);

export const fetchCalendarEvents = () =>
  axiosInstance.get<{ items: CalendarEvent[] }>(API.CALENDAR_EVENTS);

export const updateMapAreas = (
  data: { areas: EditedArea[]; deletedAreas: { id: number; new: boolean }[] },
  onSuccess: () => void,
  onError: () => void
) => axiosInstance
  .patch(`${API.MAP_AREAS}`, data)
  .then(() => {
    onSuccess();
  })
  .catch(() => {
    onError();
  });
