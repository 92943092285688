import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Header from './components/Header/Header';
import { AppRoute, SnackbarType } from './types/enums';
import {
  Root,
  ForgotPassword,
  Login,
  LoginMfa,
  ResetPassword,
  Profile,
  ChangePassword,
  Areas,
  PropertyGroups,
  Offices,
  Users,
  AdminDocumentCategories,
  AdminDocuments,
  MezzaIncoming,
  MezzaOutgoing,
  MezzaClosed,
  MezzaApprove,
  DocumentCategories,
  Documents,
  MezzaDetails,
  MezzaApproveDetails,
  MezzaLogs,
  MezzaAdminDetails,
  MessageSetup,
  MezzaRules,
  Test
} from './pages';
import Footer from './components/Footer/Footer';
import {
  canAccessAdminPage,
  getLastTokenIfNotExpired,
  hasMezzaAccess, hasMezzaApproveAccess,
  loginByToken
} from './utils/auth';
import { isAdminPage } from './utils/common';
import { axiosInstance } from './requests';
import Snackbar from './components/Snackbar/Snackbar';
import { User } from './types/types';
import CalendarCategories from './pages/Calendar/CalendarCategories';
import CalendarCategoryEvents from './pages/Calendar/CalendarCategoryEvents';
import Calendar from './pages/Calendar/Calendar';

import './App.scss';

export const UserContext = React.createContext<{
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}>({ user: undefined, setUser: () => {} });

export const NotificationContext = React.createContext<
  (message: string, type: SnackbarType) => void
>(() => {});

const App = () => {
  const [user, setUser] = useState<User>();
  const [isBooting, setIsBooting] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [notification, setNotification] = useState<{
    message: string;
    type: SnackbarType;
  }>();

  useEffect(() => {
    const title = isAdminPage() ? 'GRUPPO T.F.M. ADMIN' : 'GRUPPO T.F.M.';
    document.title = title;
  }, []);

  useEffect(() => {
    const lastToken = getLastTokenIfNotExpired();

    if (lastToken) {
      loginByToken(lastToken, notify)
        .then((res) => {
          if (res) {
            setUser(res);
          }
        })
        .finally(() => {
          setIsBooting(false);
        });
    } else {
      setIsBooting(false);
    }

    axiosInstance.interceptors.request.use((config) => {
      setShowLoader(true);

      return config;
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        setShowLoader(false);

        return response;
      },
      (err) => {
        setShowLoader(false);

        return Promise.reject(err);
      }
    );
  }, []);

  const notify = useCallback((message: string, type: SnackbarType) => {
    setNotification({ message, type });

    setTimeout(() => {
      setNotification(undefined);
    }, 3000);
  }, []);

  return (
    <>
      <BrowserRouter>
        <NotificationContext.Provider value={notify}>
          <UserContext.Provider value={{ user, setUser }}>
            <div id="side-panel" />
            <div className="app">
              <Header />

              {!isBooting && (
                <Routes>
                  {!user && (
                    <>
                      <Route path={AppRoute.TEST} element={<Test />} />
                      <Route path={AppRoute.LOGIN} element={<Login />} />
                      <Route path={AppRoute.LOGIN_MFA} element={<LoginMfa />} />
                      <Route path={AppRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
                      <Route path={AppRoute.RESET_PASSWORD} element={<ResetPassword />} />
                      <Route path="*" element={<Navigate to={AppRoute.LOGIN} replace />} />
                    </>
                  )}
                  {user && (
                    <>
                      {!isAdminPage() && (
                        <>
                          <Route path={AppRoute.ROOT} element={<Root />} />
                          <Route
                            path={AppRoute.DOCUMENT_CATEGORIES}
                            element={<DocumentCategories />}
                          />
                          <Route path={AppRoute.DOCUMENTS} element={<Documents />} />
                          <Route path={AppRoute.CALENDAR} element={<Calendar />} />
                        </>
                      )}
                      <Route path={AppRoute.PROFILE} element={<Profile />} />
                      <Route path={AppRoute.CHANGE_PASSWORD} element={<ChangePassword />} />
                      <Route path={AppRoute.USER_NOTIFICATIONS} element={<MessageSetup />} />
                      {(hasMezzaAccess(user) || hasMezzaApproveAccess(user)) && (
                        <>
                          <Route path={AppRoute.MEZZA_INCOMING} element={<MezzaIncoming />} />
                          <Route path={AppRoute.MEZZA_OUTGOING} element={<MezzaOutgoing />} />
                          <Route path={AppRoute.MEZZA_CLOSED} element={<MezzaClosed />} />
                          <Route path={AppRoute.MEZZA_APPROVE} element={<MezzaApprove />} />
                          <Route path={AppRoute.MEZZA_DETAILS} element={<MezzaDetails />} />
                          <Route path={AppRoute.MEZZA_APPROVE_DETAILS} element={<MezzaApproveDetails />} />
                          <Route path={AppRoute.MEZZA_RULES} element={<MezzaRules />} />
                        </>
                      )}
                      {canAccessAdminPage(user.role) && isAdminPage() && (
                        <>
                          <Route path={AppRoute.ADMIN_AREAS} element={<Areas />} />
                          <Route
                            path={AppRoute.ADMIN_PROPERTY_GROUPS}
                            element={<PropertyGroups />}
                          />
                          <Route path={AppRoute.ADMIN_OFFICES} element={<Offices />} />
                          <Route path={AppRoute.ADMIN_USERS} element={<Users />} />
                          <Route
                            path={AppRoute.ADMIN_DOCUMENT_CATEGORIES}
                            element={<AdminDocumentCategories />}
                          />
                          <Route path={AppRoute.ADMIN_DOCUMENTS} element={<AdminDocuments />} />
                          <Route path={AppRoute.MEZZA_LOG} element={<MezzaLogs />} />
                          <Route
                            path={AppRoute.ADMIN_MEZZA_DETAILS}
                            element={<MezzaAdminDetails />}
                          />
                          <Route
                            path={AppRoute.ADMIN_CALENDAR_CATEGORIES}
                            element={<CalendarCategories />}
                          />
                          <Route
                            path={AppRoute.ADMIN_CALENDAR_CATEGORY_EVENTS}
                            element={<CalendarCategoryEvents />}
                          />
                        </>
                      )}
                      <Route
                        path="*"
                        element={
                          <Navigate
                            to={
                              canAccessAdminPage(user.role) && isAdminPage()
                                ? AppRoute.ADMIN_AREAS
                                : AppRoute.ROOT
                            }
                            replace
                          />
                        }
                      />
                    </>
                  )}
                </Routes>
              )}

              <Footer />
            </div>
            {notification && (
              <div className="notification-wrapper">
                <Snackbar type={notification.type} hide={() => setNotification(undefined)}>
                  {notification.message}
                </Snackbar>
              </div>
            )}

            {showLoader && (
              <div className="loader-wrapper">
                <span className="loader" />
              </div>
            )}
          </UserContext.Provider>
        </NotificationContext.Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
