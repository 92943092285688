import { useMemo } from 'react';

import useData from '../../hooks/useData';
import { fetchMezzaList } from '../../requests';
import { MezzaGroup } from '../../types/enums';
import Common from './Common';
import useMapData from '../../hooks/useMapData';
import { constructFullAddress } from '../../utils/mezza';

const MezzaApprove = () => {
  const mapData = useMapData();
  const [mezzaList, refreshMezzaList] = useData(
    fetchMezzaList,
    'Hiba történt az Mezzák letöltése során, kérjük próbálja később.',
    MezzaGroup.NEED_APPROVAL
  );

  const formattedMezzaList = useMemo(
    () =>
      mezzaList.map((it) => ({
        ...it,
        officeNameToDisplay: it.recipient_office?.name,
        address: constructFullAddress(it, mapData)
      })),
    [mezzaList, mapData]
  );

  return <Common refresh={refreshMezzaList} mezzaList={formattedMezzaList} listType="approving" />;
};

export default MezzaApprove;
