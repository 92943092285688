import { titles, buttonLabels } from '../../data/labels';
import { ButtonType, PropertyCategory, PropertyType } from '../../types/enums';
import { MapData, Office, User } from '../../types/types';
import Popup from '../Popup/Popup';
import { MezzaFormField, MezzaFormValues } from './MezzaForm';
import { CATEGORY_OPTIONS } from './inputs/Category';
import AddressValidation from './AddressValidation';
import { TYPE_OPTIONS_BY_CATEGORY, SUBTYPES_BY_TYPE } from '../../utils/mezza';

import styles from './MezzaOverview.module.scss';
import { hasMezzaApproveAccess, isAdmin } from '../../utils/auth';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';

interface MezzaOverviewProps {
  values: MezzaFormValues;
  mapData: MapData;
  offices: Office[];
  onSave: () => void;
  onExit: () => void;
  onBack: () => void;
  setCheckAddress: (state: number) => void;
  initialId: number | undefined;
}

const MezzaOverview = ({
  values,
  mapData,
  offices,
  onSave,
  onExit,
  onBack,
  setCheckAddress,
  initialId
}: MezzaOverviewProps) => {
  const { user } = useContext(UserContext);
  const [ checked, setChecked ] = useState(false);

  useEffect(() => {
    if(initialId) setChecked(true);
  }, []);
  const readyToSave = () => {
    if(checked) {
      onSave();
    }
  };

  return (
    <Popup
      show
      title={titles.checkMezza}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.back,
          type: ButtonType.PRIMARY,
          action: onBack,
          inverse: true
        },
        {
          title: buttonLabels.mezzaSend,
          type: ButtonType.PRIMARY,
          action: readyToSave
        }
      ]}
    >
      <div className={styles.wrapper}>
        <div>
          Eladó/Kiadó:
          <span>
            {CATEGORY_OPTIONS.find(({ key }) => key === values[MezzaFormField.CATEGORY])?.value}
          </span>
        </div>
        <div>
          Típus:
          <span>
            {
              TYPE_OPTIONS_BY_CATEGORY[values[MezzaFormField.CATEGORY] as PropertyCategory].find(
                ({ key }) => key === values[MezzaFormField.TYPE]
              )?.value
            }
          </span>
        </div>
        <div>
          Altípus:
          <span>
            {
              (SUBTYPES_BY_TYPE[values[MezzaFormField.TYPE] as PropertyType] ?? []).find(
                ({ key }) => key === values[MezzaFormField.SUB_TYPE]
              )?.value
            }
          </span>
        </div>
        <div>
          Cím:
          <span>
            {mapData.counties.find(({ id }) => +id === Number(values[MezzaFormField.COUNTY]))
              ?.name ?? ''}{' '}
            {mapData.districts.find(({ id }) => +id === Number(values[MezzaFormField.DISTRICT]))
              ?.name ?? ''}
            {', '}
            {values[MezzaFormField.ZIP]} {values[MezzaFormField.STREET]}{' '}
            {values[MezzaFormField.STREET_TYPE]} {values[MezzaFormField.HOUSE_NUMBER]}{' '}
            {values[MezzaFormField.BUILDING]} {values[MezzaFormField.STAIRS]}{' '}
            {values[MezzaFormField.FLOOR]} {values[MezzaFormField.DOOR]}
          </span>
        </div>
        <div>
          Eladó neve:
          <span>{values[MezzaFormField.CLIENT_NAME]}</span>
        </div>
        <div>
          Eladó telefonszáma:
          <span>{values[MezzaFormField.CLIENT_PHONE]}</span>
        </div>
        <div>
          Eladó e-mail címe:
          <span>{values[MezzaFormField.CLIENT_EMAIL]}</span>
        </div>
        <div>
          Iroda:
          <span>
            {offices.find(({ id }) => +id === +values[MezzaFormField.TO_OFFICE_ID])?.name}
          </span>
        </div>
        <div>
          Megjegyzés:
          <span>{values[MezzaFormField.NOTE]}</span>
        </div>
        {(!initialId || (!isAdmin(user as User) && !hasMezzaApproveAccess(user as User))) && (
        <AddressValidation
          setCheckAddress={setCheckAddress}
          setChecked={setChecked}
          initialId={initialId}
          settlements={mapData.settlements}
          counties={mapData.counties}
          districts={mapData.districts}
          {...values}
        />
        )}
      </div>
    </Popup>
  );
};

export default MezzaOverview;
