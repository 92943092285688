export const titles = {
  offices: 'Irodák',
  officeDetails: 'Iroda adatai',
  profileData: 'Profil adatok',
  changePassword: 'Jelszó módosítása',
  manageAreas: 'Területek kezelése',
  editAreas: 'Területek szerkesztése',
  editArea: 'Terület szerkesztése',
  createArea: 'Terület hozzáadása',
  administration: 'Adminisztráció',
  deleteArea: 'Terület Törlése',
  manageOffices: 'Irodák kezelése',
  editOffice: 'Iroda szerkesztése',
  createOffice: 'Iroda hozzáadása',
  deleteOffice: 'Iroda törlése',
  manageUsers: 'Felhasználók kezelése',
  editUser: 'Felhasználó szerkesztése',
  createUser: 'Felhasználó felvétele',
  deleteUser: 'Felhasználó törlése',
  categories: 'Kategóriák',
  newCategory: 'Új kategória',
  createDocument: 'Új dokumentum',
  editCategory: 'Kategória szerkesztése',
  editDocument: 'Dokumentum  szerkesztése',
  deleteDocumentCategory: 'Kategória törlése',
  deleteDocument: 'Dokumentum törlése',
  documents: 'Dokumentumok',
  mezzaSystem: 'Mezza Rendszer',
  editMezza: 'Mezza szerkesztése',
  createMezza: 'Új Mezza',
  checkMezza: 'Mezza ellenőrzése',
  info: 'Info',
  mezzaInfo: 'Mezza Infó',
  mezzaLog: 'Mezza Log',
  messageSetup: 'Üzenet beállítások',
  manageCalendarCategories: 'Naptár Kategóriák',
  newEvent: 'Új esemény',
  editEvent: 'Esemény szerkesztése',
  event: 'Esemény',
  calendar: 'Naptár'
};

export const inputLabels = {
  search: 'Keresés',
  areaCode: 'Terület kód',
  officeName: 'Iroda neve',
  officePhone: 'Iroda telefonszám',
  officeEmail: 'Iroda e-mail cím',
  address: 'Cím',
  managerName: 'Irodavezető',
  phone: 'Telefonszám',
  email: 'E-mail cím',
  newPassword: 'Új jelszó',
  newPasswordAgain: 'Új jelszó megerősítése',
  password: 'Jelszó',
  status: 'Státusz',
  linkOffice: 'Iroda hozzárendelése',
  city: 'Település neve',
  street: 'Közterület neve és jellege',
  even: 'Páros',
  odd: 'Páratlan',
  numberOfEstates: 'Ingatlanszám',
  numberOfEstatesGovt: 'Ebből önkormányzati',
  verifyDelete: 'Írja be a mezőbe a DELETE kifejezést megerősítésképpen.',
  name: 'Név',
  accessRight: 'Jogosultság',
  getsEmails: 'Kap üzeneteket',
  mezzaApprover: 'Mezza jóváhagyásra jogosult',
  documentCategoryName: 'Kategória neve',
  description: 'Leírás',
  documentTitle: 'Cím',
  file: 'Fájl',
  documentCategoryId: 'Kategória',
  userRole: 'Felhasználói szint',
  saleOrRent: 'Eladó / Kiadó',
  propertyType: 'Típus',
  propertySubType: 'Altípus',
  county: 'Vármegye',
  zip: 'Irányítószám',
  settlements: 'Kerület / Járás',
  districts: 'Városrész / Település',
  streetName: 'Közterület neve',
  streetType: 'Közterület jellege',
  houseNumber: 'Házszám',
  clientName: 'Eladó neve',
  clientPhone: 'Eladó telefonszáma',
  clientEmail: 'Eladó e-mail címe',
  office: 'Iroda',
  recipientOffice: 'Címzett iroda',
  note: 'Megjegyzés',
  building: 'Épület',
  stairs: 'Lépcsőház',
  floor: 'Emelet',
  door: 'Ajtó',
  user: 'Felhasználó',
  activity: 'Tevékenység',
  state: 'Státusz',
  date: 'Dátum',
  mezza: 'Mezza',
  naming: 'Elnevezés',
  place: 'Helyszín',
  start: 'Kezdés',
  end: 'Eddig',
  dateTime: 'Időpont',
  category: 'Kategória'
};

export const placeholders = {
  search: 'Kérjük, írja be a keresett kifejezést vagy részletet.',
  newPassword: 'Kérjük, adja meg új jelszavát.',
  newPasswordAgain: 'Kérjük, erősítse meg új jelszavát.',
  password: 'Kérjük, adja meg a jelszavát.',
  areaCode: 'Kérjük, adja meg a terület kódját.',
  select: 'Kérjük, válasszon.',
  city: 'Kérjük adja meg a település nevét.',
  street: 'Kérjük adja meg a közterület jellegét',
  even: 'Páros házszámok',
  odd: 'Páratlan házszámok',
  numberOfEstates: 'Ingatlanszám',
  numberOfEstatesGovt: 'Önkormányzati ingatlanszám',
  officeName: 'Kérjük adja meg az iroda nevét',
  address: 'Kérjük adja meg az iroda címét',
  managerName: 'Kérjük adja meg az irodavezető nevét',
  managerPhone: 'Kérjük adja meg az irodavezető telefonszámát',
  managerEmail: 'Kérjük adja meg az irodavezető e-mail címét',
  officePhone: 'Kérjük adja meg az iroda telefonszámát',
  officeEmail: 'Kérjük adja meg az iroda e-mail címét',
  name: 'Kérjük adja meg a felhasználó nevét',
  userEmail: 'Kérjük adja meg a felhasználó e-mail címét',
  userPhone: 'Kérjük adja meg a felhasználó telefonszámát',
  documentCategoryName: 'Kérjük adja meg a dokumentum kategória nevét',
  documentCategoryDescription: 'Kérjük adja meg a dokumentum kategória leírását',
  documentTitle: 'Kérjük adja meg a dokumentum címét',
  documentDescription: 'Kérjük adja meg a dokumentum leírását',
  calendarCategoryName: 'Kérjük adja meg a kategória nevét',
  calendarCategoryDescription: 'Kérjük adja meg a kategória leírását',
  calendarEventName: 'Kérjük adja meg az esemény nevét',
  calendarEventPlace: 'Kérjük adja meg az esemény helyszínét',
  calendarEventDescription: 'Kérjük adja meg az esemény leírását'
};

export const buttonLabels = {
  view: 'Megtekintés',
  close: 'Bezárás',
  search: 'Keresés',
  resetToDefault: 'Alaphelyzetbe állítás',
  logout: 'Kilépés',
  profile: 'Profil',
  homePage: 'Főoldal',
  editPassword: 'Jelszó módosítás',
  savePassword: 'Beállítások mentése',
  areas: 'Területek',
  offices: 'Irodák',
  users: 'Felhasználók',
  edit: 'Szerkesztés',
  delete: 'Törlés',
  saveSettings: 'Beállítások mentése',
  saveData: 'Adatok mentése',
  cancel: 'Mégsem',
  editMap: 'Térkép szerkesztése',
  newArea: 'Új terület',
  newOffice: 'Új iroda',
  newUser: 'Új felhasználó',
  documents: 'Dokumentumtár',
  newDocumentCategory: 'Új kategória',
  newDocument: 'Új dokumentum',
  more: 'Tovább',
  download: 'Letöltés',
  mezzaIncoming: 'Fogadott',
  mezzaOutgoing: 'Küldött',
  mezzaClosed: 'Lezárt',
  mezzaApprove: 'Engedélyezés',
  mezzaNew: 'Új mezza',
  mezzaSend: 'Mezza küldése',
  back: 'Vissza',
  assign: 'Hozzárendelés',
  changeStatus: 'Státusz módosítása',
  mezzaLog: 'Mezza Log',
  save: 'Mentés',
  messageSetup: 'Üzenet beállítások',
  mezzaSave: 'Mezza mentése',
  calendar: 'Naptár',
  newCalendarCategory: 'Új kategória',
  newCalendarEvent: 'Új esemény',
  rules: 'Szabályzat'
};

export const helperLabels = {
  searchInOffices: 'Keresés az irodák között',
  searchInUsers: 'Keresés a felhasználók között',
  searchInPropertyGroups: 'Keresés a területcsoportok között',
  searchInAreas: 'Keresés a területek között',
  searchInDocumentCategories: 'Keresés a dokumentum kategóriák között',
  searchInDocuments: 'Keresés a dokumentumok között',
  searchInMezzas: 'Keresés a mezza bejegyzések között',
  searchInCalendarCategories: 'Keresés a kategóriák között',
  searchInCalendarEvents: 'Keresés az események között'
};

export const pagination = {
  start: 'Ugrás az elejére',
  end: 'Ugrás a végére',
  previous: 'Előző',
  next: 'Következő'
};

export const errors = {
  officeIsMandatory: 'Az iroda kiválasztása kötelező.',
  officeAlreadyOccupied: 'Ehhez az irodához már tartozik Foglalt terület.',
  codeShouldBeMin2Chars: 'A terület kódjának legalább 2 karaktert kell tartalmaznia.',
  mandatoryTextField: 'Kötelező szöveges mező.',
  mandatoryNumberField: 'Kötelező numerikus mező.',
  emailFormat:
    'Az email formátuma nem megfelelő.\r\nTöbb email címet vesszővel elválasztva adhat meg.',
  phoneNumber: 'Csak számokat, és a következő jeleket tartalmazhatja: ()+- és szóköz',
  delete: 'A beírt kifejezés nem megfelelő.',
  areaId: 'Területi felelős és Irodavezető jogosultságok esetén kötelező területkódot is választani'
};
